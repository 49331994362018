
import '../assets/css/Header.css';
import React from 'react';

const Header = ({ toggleSidebar }) => {
  return (
    <div className="header">
      <div className="menu-icon" onClick={toggleSidebar}>
        <div className="menu-bar" />
        <div className="menu-bar" />
        <div className="menu-bar" />
      </div>
      <h1>Nombre de tu App</h1>
    </div>
  );
};

export default Header;
