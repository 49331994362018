import "../assets/css/main.css"
import "../assets/css/customMica.css"
import img from "../assets/img/icons/dw-i-1.svg"
import ListServices from "./ListServices";
function OurServices() {
    return(
        <div /* class = "d-grid justify-content-center" */ className="headerClientSoftware">
            <div class="text-title text-center">
                <span>
                    NUESTROS SERVICIOS
                </span>
            </div>
            <div>
                <ListServices/>
            </div>
        </div>
    );
}
export default OurServices;