// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
body {
    max-width: 100%; 
    margin: 0 auto; 
    overflow-x: hidden; 
  }
  html{
    max-width: 100%; 
    margin: 0 auto; 
    overflow-x: hidden; 
  }
  .section-space{
    height: 83px;
  }`, "",{"version":3,"sources":["webpack://./src/assets/css/responsive.css"],"names":[],"mappings":";AACA;IACI,eAAe;IACf,cAAc;IACd,kBAAkB;EACpB;EACA;IACE,eAAe;IACf,cAAc;IACd,kBAAkB;EACpB;EACA;IACE,YAAY;EACd","sourcesContent":["\nbody {\n    max-width: 100%; \n    margin: 0 auto; \n    overflow-x: hidden; \n  }\n  html{\n    max-width: 100%; \n    margin: 0 auto; \n    overflow-x: hidden; \n  }\n  .section-space{\n    height: 83px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
