import React, { useState } from "react";
import esp from "../assets/img/Inicio/bandera-esp.svg"
import eeuu from "../assets/img/Inicio/bandera-eeuu.svg"
import "../assets/css/imageSelect.css"
const CodTelImage = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  

  return (
    <div className="image-select nav-link enlacesNavBar">
      <div className="selected-option-cod col-3" onClick={() => setIsOpen(!isOpen)}>
        {selectedOption ? (
          <>
            <img className="imageLenguage" src={selectedOption.image} alt={selectedOption.label} /><span className="cod-tel">{selectedOption.label}</span><svg width="16px" height="16px" viewBox="-4.8 -4.8 33.60 33.60" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000" stroke-width="0.00024000000000000003"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7071 14.7071C12.3166 15.0976 11.6834 15.0976 11.2929 14.7071L6.29289 9.70711C5.90237 9.31658 5.90237 8.68342 6.29289 8.29289C6.68342 7.90237 7.31658 7.90237 7.70711 8.29289L12 12.5858L16.2929 8.29289C16.6834 7.90237 17.3166 7.90237 17.7071 8.29289C18.0976 8.68342 18.0976 9.31658 17.7071 9.70711L12.7071 14.7071Z" fill="#000000"></path> </g></svg>
          </>
        ) : (
         <> <img className="imageLenguage" src={props.options[0].image} alt="Español"></img><span className="cod-tel">{props.options[0].label}</span><svg width="16px" height="16px" viewBox="-4.8 -4.8 33.60 33.60" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000" stroke-width="0.00024000000000000003"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7071 14.7071C12.3166 15.0976 11.6834 15.0976 11.2929 14.7071L6.29289 9.70711C5.90237 9.31658 5.90237 8.68342 6.29289 8.29289C6.68342 7.90237 7.31658 7.90237 7.70711 8.29289L12 12.5858L16.2929 8.29289C16.6834 7.90237 17.3166 7.90237 17.7071 8.29289C18.0976 8.68342 18.0976 9.31658 17.7071 9.70711L12.7071 14.7071Z" fill="#000000"></path> </g></svg></>
        )}
      </div>
      {isOpen && (
        <div className="options-list-cod col-9">
          <ul className="options-select-cod">
            {props.options.map((option) => (
              <li className="option-list-cod" key={option.value} >
                <button onClick={() => handleOptionClick(option)} className="option-select-cod">
                  <img className="imageLenguage" src={option.image} alt={option.label} /><span class="col-4">{option.label}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CodTelImage;
