import React, { useState } from "react";
import flecha1Image from '../assets/img/imgen/icon/flecha1Image.svg';
import flecha2Image from '../assets/img/imgen/icon/flecha2Image.svg';
import flecha1Blue from '../assets/img/imgen/icon/flecha1Blue.svg';
import flecha2Blue from '../assets/img/imgen/icon/flecha2Blue.svg';
import flecha1Green from '../assets/img/imgen/icon/flecha1green.svg';
import flecha2Green from '../assets/img/imgen/icon/flecha2green.svg';
import CarouselImage from "./CarouselImage";
import "../assets/css/carousel.css";

function Carousel({ images, isSoftware, max }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHoveringLeft, setIsHoveringLeft] = useState(false);
  const [isHoveringRight, setIsHoveringRight] = useState(false);

  /* const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? 0 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? images.length - 1 : prevIndex + 1
    );
  }; */
  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const nextSlide = () => {
    if(currentIndex+max<=images.length-1){
      if (currentIndex < images.length - 1) {
        setCurrentIndex(currentIndex + 1);
      }
    }  
  };
  const visibleImages = images.slice(currentIndex, currentIndex + max);

  return (
    <div className="slider">
      <button
        className={
          isSoftware ? "carousel-button-software" : "carousel-button-client"
        }
        onClick={prevSlide}
        onMouseEnter={() => setIsHoveringLeft(true)}
        onMouseLeave={() => setIsHoveringLeft(false)}
      >
        {isSoftware ? <img
          className="flecha2Image"
          src={isHoveringLeft ? flecha1Green : flecha1Image}
          alt="Flecha derecha"
        />:<img
        className="flecha2Image"
        src={isHoveringLeft ? flecha1Blue : flecha1Image}
        alt="Flecha derecha"
      />}
      </button>
      <div
        className={
          isSoftware
            ? "carousel-slide-container-software"
            : "carousel-slide-container-client"
        }
      >
        {visibleImages.map((image, index) => (
          <>
            {isSoftware ? (
              <CarouselImage
                key={index}
                src={image.imagenSoftware}
                alt={image.alt}
                description={image.description}
              />
            ) : (
              <div
                key={index}
                className={
                  isSoftware ? "contenedorImgSoftware" : "contenedorImgClient"
                }
              >
                <img
                  className={isSoftware ? "imgSoftware" : "imgClient"}
                  src={image}
                  alt={`Slide ${currentIndex + index}`}
                />
              </div>
            )}
          </>
        ))}
      </div>
      <button
        className={
          isSoftware ? "carousel-button-software" : "carousel-button-client"
        }
        onClick={nextSlide}
        onMouseEnter={() => setIsHoveringRight(true)}
        onMouseLeave={() => setIsHoveringRight(false)}
      >
        {isSoftware ? <img
          className="flecha2Image"
          src={isHoveringRight ? flecha2Green : flecha2Image}
          alt="Flecha derecha"
        />:<img
        className="flecha2Image"
        src={isHoveringRight ? flecha2Blue : flecha2Image}
        alt="Flecha derecha"
      />}
      </button>
    </div>
  );
}

export default Carousel;
