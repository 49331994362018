import "../assets/css/main.css"
import "../assets/css/customMica.css"
import img1 from "../assets/img/OurServices/img-ourServices-1.jpeg"
import img2 from "../assets/img/OurServices/img-ourServices-2.jpeg"
import img3 from "../assets/img/OurServices/img-ourServices-3.jpeg"
import img4 from "../assets/img/OurServices/img-ourServices-4.jpeg"
import img5 from "../assets/img/OurServices/img-ourServices-5.jpeg"
import Service from "./Service";

function ListServices() {
    return(
        
        <div class = "d-grid gap-row-1 justify-content-center">
                <div class="d-grid gap-6 mb-4">
                    <Service img={img1} message={"Desarrolllo de software a medida."}/>
                    <Service img={img2} message={"Desarrollo de aplicaciones móviles."}/>
                    <Service img={img2} message={"Desarrollo de aplicaciones móviles."}/>
                
                    <Service img={img3} message={"Desarrollo de páginas WEB."}/>
                    <Service img={img4} message={"EPR, CMS, CRM."}/>
                    <Service img={img5} message={"Asesoramiento en desarrollo de sistemas."}/>
                    
                </div>
        </div>
    );
}
export default ListServices;