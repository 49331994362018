import React, { useState } from "react";
import "../assets/css/main.css";
import "../assets/css/customMica.css";
import "../assets/css/inicio.css";
import esp from "../assets/img/Inicio/bandera-esp.svg";
import eeuu from "../assets/img/Inicio/bandera-eeuu.svg";
import logoIntelligentSociety from "../assets/img/Inicio/logo-intelligent-society.svg";
import ImageSelect from "./ImageSelect";
import MenuHamburguer from "./MenuHamburguer";
import Sidebar from "./Sidebar";
import Header from "./Header";
const NavBar = (props) => {
  const options = [
    { value: "spanish", label: "spanish", image: esp },
    { value: "english", label: "english", image: eeuu },
  ];
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div>
      <header className="main-header w-100 justify-content-between px-5 position-fixed z-ind">
        <a
          href="/"
          className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
        >
          <img
            src={logoIntelligentSociety}
            alt="logo"
            className="img-fluid logo-white"
          ></img>
          {/* <img src="assets/img/logo-color.png" alt="logo" className="img-fluid logo-color"></img> */}
        </a>
        <nav className="navbar navbar-expand-xl navbar-dark sticky-header z-10">
          <div className=" d-flex align-items-center justify-content-lg-between position-relative">
            <div className="clearfix"></div>
            <div className="collapse navbar-collapse navBar">
            
              <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                <li>
                  <a className="nav-link enlacesNavBar" href="/">
                    INICIO
                  </a>
                </li>
                <li>
                  <a href="#pageUs" class="nav-link enlacesNavBar">
                    NOSOTROS
                  </a>
                </li>
                <li>
                  <a href="#services" class="nav-link enlacesNavBar">
                    SERVICIOS
                  </a>
                </li>
                <li>
                  <a className="nav-link enlacesNavBar" href="#softCommercial">
                    SOFTWARE COMERCIAL
                  </a>
                </li>
                <li>
                  <a href="#ourClients" class="nav-link enlacesNavBar">
                    NUESTROS CLIENTES
                  </a>
                </li>
                <li>
                  <a className="nav-link enlacesNavBar" href="#contacts">
                    CONTÁCTOS
                  </a>
                </li>
                <li>
                  <ImageSelect 
                    options={options}
                    
                  />
                </li>
              </ul>
            </div>
          </div>
          <MenuHamburguer toggleSidebar={props.toggleSidebar} />
        </nav>
        
        
        {/* <Sidebar isOpen={isSidebarOpen} /> */}
      </header>
    </div>
  );
};

export default NavBar;
