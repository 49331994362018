import React from 'react'
import "../assets/css/ourClient.css"
import Carousel from './Carousel';
import tantra from "../assets/img/OurClients/tantra.svg"
import blueIce from "../assets/img/OurClients/blue-ice.svg"
import puntoMusical from "../assets/img/OurClients/punto-musical.svg"
import maydana from "../assets/img/OurClients/maydana.svg"
import grupoVardona from "../assets/img/OurClients/grupo-vardona.svg"
import HeaderClientsOrSoftware from './HeaderClientsOrSoftware';

const OurClients = () => {
    
  const mockImagenes = [
    tantra,
    blueIce,
    puntoMusical,
    maydana,
    grupoVardona,
];
  return (
    <div className='ours-client'>
        <HeaderClientsOrSoftware
            titleHeader1={"NUESTROS"}
            titleHeader2={"CLIENTES"}
            paragraphHeader={"Empresas que pusieron su confianza en nosotros."}
        />
        <Carousel images={mockImagenes} max={5}/>
    </div>
  )
}

export default OurClients