// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #333;
    color: white;
  }
  
  .menu-icon {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  
  .menu-bar {
    width: 25px;
    height: 3px;
    margin: 2px 0;
    background-color: white;
    transition: 0.4s;
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/css/Header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,gBAAgB;EAClB","sourcesContent":[".header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 20px;\n    background-color: #333;\n    color: white;\n  }\n  \n  .menu-icon {\n    display: flex;\n    flex-direction: column;\n    cursor: pointer;\n  }\n  \n  .menu-bar {\n    width: 25px;\n    height: 3px;\n    margin: 2px 0;\n    background-color: white;\n    transition: 0.4s;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
