import React from 'react';
import '../assets/css/customSele.css';

const ContactButton = ({color}) => {
  return (
    <button onClick={()=>{window.location.href="#contacts"}} className="contact-button" style={{backgroundColor: color}}>
      Contáctanos
    </button>
  );
};

export default ContactButton;
