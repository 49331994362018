import React from 'react'
import "../assets/css/softwareComercial.css"
import Carousel from './Carousel'
import HeaderClientsOrSoftware from './HeaderClientsOrSoftware';
import demeter from "../assets/img/SoftwareComercial/Demeter.jpeg"
import nix from "../assets/img/SoftwareComercial/Nix.jpeg"
import venus from "../assets/img/SoftwareComercial/Venus.jpeg"
import eter from "../assets/img/SoftwareComercial/Eter.jpeg"
const SoftwareComercial = () => {
    const mockImagenes = [
      {imagenSoftware:demeter,alt:"Demeter",description:"CONTROL DE VENTAS E INVENTARIOS"},
      {imagenSoftware:nix,alt:"Nix",description:"CONTROL DE CRÉDITOS Y PRÉSTAMOS"},
      {imagenSoftware:venus,alt:"Venus",description:"CONTROL DE ENVÍOS Y ENCOMIENDAS"},
      {imagenSoftware:eter,alt:"Eter",description:"CONTROL DE RESTAURANTES"},
      {imagenSoftware:eter,alt:"Eter",description:"CONTROL DE RESTAURANTES"},
      {imagenSoftware:eter,alt:"Eter",description:"CONTROL DE RESTAURANTES"},
    ];
  return (
    <div className='software-comercial'>
        <HeaderClientsOrSoftware
            titleHeader1={"SOFTWARE"}
            titleHeader2={"COMERCIAL"}
            isSoftware={true}
            paragraphHeader={"Sistemas Web completos, enfocados a rubros y actividades específicas."}
        />
        <Carousel images={mockImagenes} isSoftware={true} max={4}/>
    </div>
  )
}

export default SoftwareComercial