/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import '../assets/css/customSele.css'
import '../assets/css/main.css'
import backgroundImage from '../assets/img/imgen/footerimg.svg';
import logoImage from '../assets/img/imgen/intelligentSociety.svg';
import facebookIcon from '../assets/img/imgen/icon/facebook.svg';
import instagramIcon from '../assets/img/imgen/icon/instagram.svg';
import tiktokIcon from '../assets/img/imgen/icon/tiktok.svg';
import linkedinIcon from '../assets/img/imgen/icon/linkedin.svg';

function Footer() {
  return (
    <footer className="footer" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="container-footer">
        <div className="containerImg">
          <div className="logoAndIcons">
            <img className="logoImage" src={logoImage} alt="Descripción de la imagen" />
            <div className="footerIcons">
              <a href="#"><img src={facebookIcon} alt="Facebook Icon" /></a>
              <a href="#"><img src={instagramIcon} alt="Instagram Icon" /></a>
              <a href="#"><img src={linkedinIcon} alt="Linkedin Icon" /></a>
              <a href="#"><img src={tiktokIcon} alt="TikTok Icon" /></a>
            </div>
          </div>
        </div>
        <div className="footerText">
          © 2023 Intelligent Society. Todos los derechos reservados.
        </div>
      </div>
    </footer>
  );
}

export default Footer;