// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100%;
  background-color: #071040;
  /* padding-top: 83px; */
  top: 83px;
  color: white;
  transition: right 0.4s;
  z-index: 99;
}

.sidebar.open {
  right: 0;
}

.content {
  transition: margin-right 0.4s;
  margin-right: 0; /* Asegura que el contenido no se superponga al cargar */
}

.content-open {
  margin-right: 300px;
}
.list-navbar{
  list-style: none;
  margin: 0;
  padding: 0;
}
.option-list-navbar{
  padding: 10px 30px;
  color: #fff;
  display: flex;
  align-items: center;
}
.option-list-navbar:hover{
  color: #fff;
}
@media screen and (max-width:600px){
  .sidebar{
    width: 200px;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/css/Sidebar.css"],"names":[],"mappings":"AACA;EACE,eAAe;EACf,MAAM;EACN,aAAa;EACb,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,uBAAuB;EACvB,SAAS;EACT,YAAY;EACZ,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE,6BAA6B;EAC7B,eAAe,EAAE,wDAAwD;AAC3E;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,SAAS;EACT,UAAU;AACZ;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,WAAW;AACb;AACA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@800&display=swap');\n.sidebar {\n  position: fixed;\n  top: 0;\n  right: -300px;\n  width: 300px;\n  height: 100%;\n  background-color: #071040;\n  /* padding-top: 83px; */\n  top: 83px;\n  color: white;\n  transition: right 0.4s;\n  z-index: 99;\n}\n\n.sidebar.open {\n  right: 0;\n}\n\n.content {\n  transition: margin-right 0.4s;\n  margin-right: 0; /* Asegura que el contenido no se superponga al cargar */\n}\n\n.content-open {\n  margin-right: 300px;\n}\n.list-navbar{\n  list-style: none;\n  margin: 0;\n  padding: 0;\n}\n.option-list-navbar{\n  padding: 10px 30px;\n  color: #fff;\n  display: flex;\n  align-items: center;\n}\n.option-list-navbar:hover{\n  color: #fff;\n}\n@media screen and (max-width:600px){\n  .sidebar{\n    width: 200px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
