import React from 'react'
import NavBar from './NavBar'
import '../assets/css/inicio.css'
import '../assets/css/main.css'
import fondoInicio from "../assets/img/Inicio/fondo-inicio.jpeg"
import ContactButton from './ContactUsButton'
const Inicio = () => {
  return (
    <div className='d-flex inicio-landing'>
        
        <section className='presentacion'>
          <div className='letras-presentacion col-lg-12 col-md-9 col-sm-7'>
            <p id='primeraLinea'>Transportamos tu negocio al </p>
            <h1 id='segundaLinea'>Mundo digital</h1>
            <p id='terceraLinea'>A través de <strong>Sistemas Web</strong>, ajustados a las necesidades de tu empresa".</p>
          </div>
          <div className='botonContacto'>
            <ContactButton
              color={"#00D89F"}
            />
          </div>
        </section>
    </div>
  )
}

export default Inicio