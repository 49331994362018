import React from 'react'
import "../assets/css/headersClientOrSoftware.css"
const HeaderClientsOrSoftware = (props) => {
  return (
    <div className='headerClientSoftware'>
      <div className='titleContainer'>
        <h1 className='titleHeader'>
          <span className={props.isSoftware ? 'titleSoft1' : 'titleClient1'}>{props.titleHeader1} </span> <span className={props.isSoftware ? 'titleSoft2' : 'titleClient2'}>{props.titleHeader2}</span>
          </h1>
       </div>
         <p className={props.isSoftware ? 'parrafoSoftware' : 'parrafoClient'}>{props.paragraphHeader}</p>
    </div>
  )
}

export default HeaderClientsOrSoftware