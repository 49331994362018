import React, { useState } from 'react'

const CarouselImage = ( props) => {
    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
      setHovered(true);
    };
  
    const handleMouseLeave = () => {
      setHovered(false);
    };
  return (
    <><div
        className="contenedorImgSoftware"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {hovered ? (
        <div className="text-hover">
            <p>SISTEMA WEB DE <strong>{props.description}</strong></p>
        </div>
      ) : (
        <img src={props.src} alt={props.alt} className="imgSoftware" />
      )}
    </div></>
  )
}

export default CarouselImage