import "../assets/css/main.css"
import "../assets/css/customMica.css"

function CardUs2({tittle, message}) {
    return(
        <div class="d-grid text-start gap-row-3 my-7">
            <h3 class="text-tittle-3">{tittle}</h3>
            <p class="text-paragraph-2">{message}</p>
        </div>
    );
}
export default CardUs2;
