import "../assets/css/main.css"
import "../assets/css/customMica.css"
import Maps from "./Maps";
import ContactUs from "./ContactUs";

function ContactUsPage() {
    return(
        <div class="row">
            <div class="d-flex justify-content-center col-lg-6 col-md-10 col-sm-12 separation">
                <div class="col-10">
                    <ContactUs/>
                </div>
            </div>
            <div class="d-flex justify-content-center col-lg-6 col-md-11 col-sm-12 separation">
                <div class="col-10">
                    <Maps/>
                </div>
            </div>
        </div>
    );
}
export default ContactUsPage;
