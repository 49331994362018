import "../assets/css/main.css"
import "../assets/css/customMica.css"
import SendButton from "../components/SendButton"
import bol from "../assets/img/Paises/bolivia.png"
import arg from "../assets/img/Paises/argentina.webp"
import chi from "../assets/img/Paises/chile.png"
import CodTelImage from "./CodTelImage"
function ContactUs() {
    const options = [
        { value: "+591", label: "+591", image: bol },
        { value: "+54", label: "+54", image: arg },
        { value: "+56", label: "+56", image: chi },
      ];
    return(
        <div>
            <div class="">
                <div class="gap-12 mb-4">
                    <h2 class="text-tittle-4">Contáctanos</h2>
                    <p class="text-paragraph-3">Llena tus datos, y nos contactaremos contigo lo más pronto posible. </p>
                </div>
                <form action="#" class="register-form">
                    <div class="row">
                        <div class="col-12">
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" id="firstName" required="" placeholder="Nombre*" aria-label="First name"/>
                            </div>
                        </div>
                        <div class="col-3">
                            {/* <select class="form-control form-select" name="budget" id="budget" required="" data-msg="Please select your budget.">
                                <option value="" selected="" disabled="">+591</option>
                                <option value="budget1">+591</option>
                                <option value="budget1">+94</option>
                                <option value="budget1">+56</option>
                            </select> */}
                            
                            <CodTelImage
                        options={options}
                        isCod={true}
                    />
                        </div>
                        <div class="col-9">
                            <div class="input-group mb-3">
                                <input type="text" class="form-control" id="phone" required="" placeholder="775-4578" aria-label="Phone"/>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-group mb-3">
                                <select class="form-control form-select" name="budget" id="budget" required="" data-msg="Please select your budget.">
                                    <option value="" selected="" disabled="">Selecciona el interés de tu consulta</option>
                                    <option value="budget1">Desarrollo de un sistema web</option>
                                    <option value="budget1">Desarrollo de un sistema móvil</option>
                                    <option value="budget1">Información acerca de nuestros productos</option>
                                    <option value="budget1">Asesoramiento en desarrollo de sistemas</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="input-group">
                                <textarea class="form-control" id="yourMessage" required="" placeholder="¿Cómo podemos ayudarte?" style={{height: "120px"}}></textarea>
                            </div>
                        </div>
                        <div class="col-12 my-6">
                            <div class="d-flex gap-11 align-items-center">
                                <input class="wh-2" type="checkbox" value="" id="flexCheckChecked"/>
                                <label class="form-check-label text-paragraph-4" for="flexCheckChecked">
                                    Me gustaría recibir información acerca de noticas o eventos de parte de Intelligent Society.
                                </label>
                            </div>
                        </div>
                    </div>
                    <SendButton/>
                    
                    {
                        
                    }
                </form>
            </div>
        </div>
    );
}
export default ContactUs;
