import React from 'react';
import '../assets/css/customSele.css';

const SendButton = () => {
  return (
    <button className="send-button">
      Enviar
    </button>
  );
};

export default SendButton;
