import "../assets/css/main.css"
import "../assets/css/customMica.css"
function Service({img, message}) {
    return(
        <div class="row justify-content-center rounded-6 bg-gray gap-6 wh-1">
            <div class="d-flex align-items-center px-6 gap-7">
                <div>
                    <img class="h-1" src={img} alt="icon"/>
                </div>
                <div class="text-start text-1">
                    <span>{message}</span>
                </div>
            </div>
        </div>
    );
}
export default Service;
