// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@500;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parrafoSoftware, .titleSoft1{
    color: #FFFFFF;
}
.parrafoClient{
    color: #071040;
}
.titleSoft2{
    color: #00D89F;
}
.parrafoSoftware,.titleSoft1,.parrafoClient,.titleSoft2{
    text-align: center;
}
.headerClientSoftware{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.titleSoft1, .titleSoft2,.titleClient1,.titleClient2{
    font-family: 'Manrope', sans-serif;
    font-weight: 800;
    font-size: 50px;
}
.parrafoClient,.parrafoSoftware{
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 24px;
}
.titleContainer{text-align: center;}`, "",{"version":3,"sources":["webpack://./src/assets/css/headersClientOrSoftware.css"],"names":[],"mappings":"AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;AAC1B;AACA;IACI,kCAAkC;IAClC,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,kCAAkC;IAClC,gBAAgB;IAChB,eAAe;AACnB;AACA,gBAAgB,kBAAkB,CAAC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@500;800&display=swap');\n.parrafoSoftware, .titleSoft1{\n    color: #FFFFFF;\n}\n.parrafoClient{\n    color: #071040;\n}\n.titleSoft2{\n    color: #00D89F;\n}\n.parrafoSoftware,.titleSoft1,.parrafoClient,.titleSoft2{\n    text-align: center;\n}\n.headerClientSoftware{\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n}\n.titleSoft1, .titleSoft2,.titleClient1,.titleClient2{\n    font-family: 'Manrope', sans-serif;\n    font-weight: 800;\n    font-size: 50px;\n}\n.parrafoClient,.parrafoSoftware{\n    font-family: 'Manrope', sans-serif;\n    font-weight: 500;\n    font-size: 24px;\n}\n.titleContainer{text-align: center;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
