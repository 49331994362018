import "../assets/css/main.css"
import "../assets/css/customMica.css"
import CardUs2 from "./CardUs2";
import img from "../assets/img/AboutUs/img-aboutUs-1.jpeg"
import ContactusButton from "../components/ContactUsButton"

function PageUs() {
    return(
        <div class="gap-row-4 justify-content-center">
            <div>
                <div class="d-grid text-center mb-3">
                    <h3 class="text-tittle-2">NOSOTROS</h3>
                    <p class="text-paragraph-1">Somos una empresa Boliviana de desarrollo de software comercial enfocado a pequeñas, medianas y grandes empresas.<br/>
                    Nuestro compromiso es digitalizar el mayor número de procesos administrativos al mayor número de clientes a nivel nacional e internacional.<br/>
                    Contamos 6 años de experiencia en el rubro.</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-7 col-md-7 col-sm-12">
                    <img class="col-12 rounded-7 h-3" src={img} alt="icon"/>
                </div>
                <div class="col justify-content-center col-lg-5 col-md-5 col-sm-12 p-6">
                    <div>
                        <CardUs2 tittle={"MISIÓN"} message={"Ofrecer un servicio especializado de alta calidad. Nuestra prioridad, agilizar procesos, reducir costes y contribuir al progreso del mercado nacional mediante el desarrollo de sofware empresarial a medida."}/>
                    </div>
                    <div class="row justify-content-center"><hr class="col-5 line"/></div>
                    <div>
                        <CardUs2 tittle={"VISIÓN"} message={"Representar al país como una de las empresas líderes en desarrollo de software empresarial. Facilitar el uso de sistemas de control administrativo al mayor número de clientes tanto a nivel nacional como internacional. "}/>
                    </div>
                    <div class="d-flex justify-content-center">
                        <ContactusButton color={"#071040"}/>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PageUs;
