import Inicio from "./components/Inicio";
import PageUs from "./components/PageUs";
import OurServices from "./components/OurServices";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import SoftwareComercial from "./components/SoftwareComercial";
import OurClients from "./components/OurClients";
import Maps from "./components/Maps";
import ContactUsPage from "./components/ContactUsPage";
import NavBar from "./components/NavBar";
import "./assets/css/customMica.css";
import "./assets/css/main.css";
import "./assets/css/responsive.css";
import { useState } from "react";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import MenuHamburguer from "./components/MenuHamburguer";
function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div className="App" >
      <div >
        <NavBar toggleSidebar={toggleSidebar} />
      </div>
      <div  className="row-1 ">
        <div >
          <div id="home" class="mtp-6 "></div>
          <Inicio />
        </div>
        <Sidebar isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen}/>
        <div >
          <div id="pageUs" /* class="mtp-6 " */ className="section-space"></div>
          <PageUs />
        </div>
        <div id="services">
          <div /* class="mtp-6 " */ className="section-space"></div>
          <OurServices />
        </div>

        <div id="softCommercial">
          <div /* class="mtp-6 " */ className="section-space"></div>
          <SoftwareComercial />
        </div>
        <div id="ourClients">
          <div /* class="mtp-6 " */ className="section-space"></div>
          <OurClients />
        </div>
        <div id="contacts">
          <div /* class="mtp-6 " */ className="section-space"></div>
          <ContactUsPage />
        </div>
        <div /* class="mtp-6 " */ className="section-space"></div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
