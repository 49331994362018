import React from "react";
import "../assets/css/Sidebar.css";
import ImageSelect from "./ImageSelect";
import esp from "../assets/img/Inicio/bandera-esp.svg";
import eeuu from "../assets/img/Inicio/bandera-eeuu.svg";
const Sidebar = ({ isOpen, setIsOpen }) => {
  const options = [
    { value: "spanish", label: "spanish", image: esp },
    { value: "english", label: "english", image: eeuu },
  ];
  return (
    <div className={` sidebar ${isOpen ? "open" : ""}`}>
      {
        <>
          <ul className="list-navbar">
            {" "}
            <li>
              <a onClick={()=>(setIsOpen(false))} className="option-list-navbar" href="#home">
                INICIO
              </a>
            </li>
            <li>
              <a onClick={()=>(setIsOpen(false))} className="option-list-navbar" href="#pageUs">
                NOSOTROS
              </a>
            </li>
            <li>
              <a onClick={()=>(setIsOpen(false))} className="option-list-navbar" href="#services">
                SERVICIOS
              </a>
            </li>
            <li>
              <a onClick={()=>(setIsOpen(false))} className="option-list-navbar" href="#softCommercial">
                SOFTWARE COMERCIAL
              </a>
            </li>
            <li>
              <a onClick={()=>(setIsOpen(false))} className="option-list-navbar" href="#ourClients">
                NUESTROS CLIENTES
              </a>
            </li>
            <li>
              <a onClick={()=>(setIsOpen(false))} className="option-list-navbar" href="#contacts">
                CONTACTOS
              </a>
            </li>
            <li className="option-list-navbar">
              <ImageSelect options={options} />
            </li>
          </ul>
        </>
      }
    </div>
  );
};

export default Sidebar;
