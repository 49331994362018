import React from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import "../assets/css/customSele.css"
import "../assets/css/main.css"
const containerStyle = {
  width: '400px',
  height: '400px'
};

const center = {
  lat: -17.39678788348324,
  lng: -66.15265461203538
};

function MyComponent() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCmKi-tSFHhF9tmb5Y54KEu1Sf-A6i-EFI"
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (<>
      <div class="gap-12 mb-4">
          <h2 class="encuentranos-text">Encuéntranos</h2>
      </div>
      <div class="mb-4 google-maps">

        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={14}
          mapContainerStyle={{width:"100%", height:"446px"}}
        >
          { /* Child components, such as markers, info windows, etc. */ }
          <></>
          <Marker key="1"  position={center} />
        </GoogleMap>
      </div></>
  ) : <></>
}

export default React.memo(MyComponent)